




import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component
export default class RouterViewBuilding extends Vue {
  @Prop() public building!: any;
}
